<template>
  <b-modal
      no-close-on-backdrop
      no-close-on-esc
      hide-header
      hide-footer
      centered
      v-model="modalShow"
  >
    <div class="d-flex flex-column align-items-center justify-center-center my-2">
      <feather-icon
          id="view-departamento"
          icon="AlertCircleIcon"
          class="text-warning mb-2"
          size="80"
      />
      <h3 class="mb-2">Atenção!</h3>
      <span class="text-center content-modal">
        O Departamento Regional selecionado é diferente da UF do endereço em questão.</br>
        Deseja continuar?
      </span>
      <div class="d-flex mt-2">
        <b-button class="mr-1" :disabled="loading" variant="primary-button" @click="confirmAction" >
          <span v-if="loading" class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
          Confirmar
        </b-button>
        <b-button :disabled="loading" variant="outline-danger" class="mr-2" @click="cancelAction">
          Cancelar
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { BModal, BButton } from 'bootstrap-vue';
export default {
  components: {
    BModal, BButton
  },

  props: {
    modalShow: {
      required: true,
      type: Boolean
    }
  },

  data() {
    return {
      loading: false
    }
  },

  methods: {
    confirmAction(modalEvent) {
      modalEvent.preventDefault();

      this.loading = true;

      this.$emit('confirmed', modalEvent);
    },

    cancelAction(modalEvent) {
      modalEvent.preventDefault();

      this.$emit('canceled', modalEvent);
    },
  },

  watch: {
    modalShow() {
      this.loading = false;
    }
  }
}
</script>

<style scoped>
.content-modal {
  max-width: 80%;
}
</style>
