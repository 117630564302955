<template>
  <section>
    <b-row>
            <b-col lg="12" md="12" sm="12">
                <b-alert
                variant="warning"
                :show="value.possui_campanha_nacional"
                class="p-1"
                >
                <span>
                  A empresa está vinculada a um grupo com campanhas ativas.
                </span>
                </b-alert>
              </b-col>
          </b-row>
    <b-row>
      <b-col xl="4" cols="12">
        <b-form-group
          label="CNAE Principal"
          label-for="cnae-input"
          label-class="font_size_label_empresa"
        >
          <validation-provider
            #default="{ errors }"
            name="CNAE"
            :rules="'length:7'"
          >
            <vue-cleave
              id="cnae-input"
              v-model="value.cnae"
              :raw="true"
              :options="mascaraCNAE"
              class="form-control"
              placeholder="0000-0/00"
              name="CNAE"
            />
            <small v-if="errors[0]" class="text-danger">
              Este campo deve ter 7 números
            </small>
            <small v-if="errorApi.cnae" class="text-danger">
              {{ errorApi.cnae }}
            </small>
            <small v-if="errorApi.error" class="text-danger">
              {{ errorApi.error }}
            </small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col v-if="pessoaEmpresa" xl="4" cols="12">
        <b-form-group
          label="FPAS"
          label-for="fpas-input"
          label-class="font_size_label_empresa"
        >
          <validation-provider
            #default="{ errors }"
            name="FPAS"
            :rules="'min:3|max:4'"
          >
            <b-form-input
              :state="
                errors.length > 0 || errorApi.fpas || errorApi.error
                  ? false
                  : null
              "
              id="fpas-input"
              v-model="value.fpas"
              placeholder="0000"
              v-mask="'####'"
            />
            <small v-if="errors[0]" class="text-danger">
              Este campo deve ter entre 3 e 4 números
            </small>
            <small v-if="errorApi.fpas" class="text-danger">
              {{ errorApi.fpas }}
            </small>
            <small v-if="errorApi.error" class="text-danger">
              {{ errorApi.error }}
            </small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col xl="4" cols="12">
        <b-form-group
          label="Número de trabalhadores"
          label-for="numero-de-trabalhadores-input"
          label-class="font_size_label_empresa"
        >
          <b-form-input
            :state="
              errorApi.numero_de_trabalhadores || errorApi.error ? false : null
            "
            id="numero-de-trabalhadores-input"
            v-model="value.numero_de_trabalhadores"
            placeholder="0000"
            v-mask="'#########'"
          />
          <small v-if="errorApi.numero_de_trabalhadores" class="text-danger">
            {{ errorApi.numero_de_trabalhadores }}
          </small>
          <small v-if="errorApi.error" class="text-danger">
            {{ errorApi.error }}
          </small>
        </b-form-group>
      </b-col>
      <b-col lg="4" cols="12">
        <b-form-group
          :state="errorApi.id_tipo_empresa || errorApi.error ? false : null"
          label="Selecione o tipo de empresa"
          label-for="tipoEmpresa-input"
          label-class="font_size_label_empresa"
        >
          <v-select
            id="tipoEmpresa-input"
            :options="tiposEmpresa"
            :value="tipoEmpresa"
            @input="selecionaTipoEmpresa"
            placeholder="Selecione o tipo de empresa"
            label="descricao"
            item-text="descricao"
            item-value="id_tipo_empresa"
            :clearable="false"
            :disabled="!pessoaEmpresa"
          >
            <span slot="no-options">Nenhuma opção selecionável.</span>
          </v-select>
          <small v-if="errorApi.id_tipo_empresa" class="text-danger">
            {{ errorApi.id_tipo_empresa }}
          </small>
          <small v-if="errorApi.error" class="text-danger">
            {{ errorApi.error }}
          </small>
        </b-form-group>
        <b-tooltip
          v-if="!pessoaEmpresa"
          target="tipoEmpresa-input"
          custom-class="tooltipNaoEditavel"
          triggers="hover"
        >
          Não editável para pessoa cpf
        </b-tooltip>
      </b-col>
        <b-col lg="4" cols="12" class="my-2">
          <div 
            class="radio-inline-spacing-valor"
          >
            <label class="font_size_label_empresa font-weight-light">Empresa de Base Nacional?</label>
            <b-tooltip
                v-if="value.possui_campanha_nacional"
                target="radio-true"
                placement="top"
                triggers="hover"
            >
              Não é possível alterar o Departamento Regional da aba atendimento em um endereço de uma empresa cujo Grupo
              de Empresas esteja em uma campanha Ativa (Em andamento)
            </b-tooltip>
            <b-form-radio
                id="radio-true"
                v-model="value.base_nacional"
                name="radio-base-nacional"
                :value="true"
                :disabled="!permissionNational || value.possui_campanha_nacional"
            >
                Sim
            </b-form-radio>
            <b-tooltip
                v-if="value.possui_campanha_nacional"
                target="radio-false"
                placement="top"
                triggers="hover"
            >
              Não é possível alterar o Departamento Regional da aba atendimento em um endereço de uma empresa cujo Grupo
              de Empresas esteja em uma campanha Ativa (Em andamento)
            </b-tooltip>
            <b-form-radio
                id="radio-false"
                v-model="value.base_nacional"
                name="radio-base-nacional"
                :value="false"
                :disabled="!permissionNational || (value.possui_campanha_nacional ? value.possui_campanha_nacional : false)"
            >
                Não
            </b-form-radio>
          </div>
          <small v-if="errorApi.base_nacional" class="text-danger">
                {{ errorApi.base_nacional }}
            </small>
        </b-col>
        <b-col lg="4" cols="12" v-if="value.base_nacional">
          <b-form-group 
            :state="errorApi.id_grupo || errorApi.error ? false:null"
            label="Grupo"
            label-for="grupoEmpresa-input"
            label-class="font_size_label_empresa"
          >
            <v-select
              id="grupoEmpresa-input"
              :options="gruposEmpresa"
              v-model="grupoSelected"
              @input="selecionaGrupo"
              placeholder="Selecione o grupo da empresa"
              label="descricao"
              item-text="descricao"
              item-value="id_grupo"
              :disabled="!permissionNational || value.possui_campanha_nacional"
            >
              <span slot="no-options">Nenhuma opção selecionável.</span>
            </v-select>
            <small v-if="errorApi.id_grupo" class="text-danger">
                {{ errorApi.id_grupo }}
            </small>
            <small v-if="errorApi.error" class="text-danger">
                {{ errorApi.error }}
            </small>
          </b-form-group>
        </b-col>
    </b-row>
    <b-row v-if="!adminEmpresa">
      <b-col lg="12" cols="12">
        <NaoEhConvenio
          :value="value"
          :errorApi="errorApi"
          :naoIndustria="naoIndustria"
        />
      </b-col>
    </b-row>
  </section>
</template>
<script>
import {
  BCard,
  BFormInput,
  BCol,
  BRow,
  BOverlay,
  BFormGroup,
  BFormRadio,
  BTooltip,
  BAlert
} from "bootstrap-vue";
import { ValidationProvider } from "vee-validate";
import VueCleave from "vue-cleave-component";
import vSelect from "vue-select";
import NaoEhConvenio from "./partes/nao-eh-convenio.vue";
import { actions, subjects } from "@/libs/acl/rules";

export default {
  components: {
    BCard,
    BFormInput,
    BCol,
    BRow,
    BOverlay,
    BFormGroup,
    BFormRadio,
    BTooltip,
    vSelect,
    ValidationProvider,
    VueCleave,
    NaoEhConvenio,
    BAlert
  },

  props: {
    pessoaEmpresa: {
      type: Boolean,
      required: true,
    },
    errorApi: {
      type: Object,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
    tipoEmpresa: {
      type: Object,
      required: false,
    },
    naoIndustria: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      tiposEmpresa: [],
      gruposEmpresa: [],
      operatorDepartments: [],
      mascaraCNAE: {
        delimiters: ["-", "/"],
        blocks: [4, 1, 2],
        numericOnly: true,
      },
      casoEdicao: false,
      erros: null,
      permissionNational: this.$can(actions.VISUALIZAR, subjects.TIPO_ATUACAO_EMPRESA_BASE_NACIONAL),
      adminEmpresa: this.verifyPermissions(actions.ATUALIZAR),
      oldOperator: null,
      oldGroup:null,
      selectGroupIsBlocked: false,
      grupoSelectedData: null,
    }
  },

  mounted() {
    this.buscaTiposEmpresaPreenche();
    if(this.permissionNational){
      this.buscaGruposEmpresa()
      this.buscaDepartamento()
    }
  },

  methods: {
    buscaTiposEmpresaPreenche() {
      this.$http
        .get(this.$api.getTipoEmpresa())
        .then(({ data }) => {
          this.tiposEmpresa = data;
        })
        .catch(() => {
          this.$helpers.modalErroServidorPadrao();
        });
    },
    buscaGruposEmpresa(){
      const params = {
        todos: true,
        id_grupo: this.value.id_grupo,
        colunaNome: 'descricao',
        colunaOrdem: 'asc'
      }
      this.$http.get(this.$api.getAndPostGrupo(), {params}).then(({ data }) => {
        data.map((grupo) => {
          this.gruposEmpresa.push({
            id_grupo: grupo.id_grupo,
            descricao: grupo.descricao
          })
        })

      }).catch((error) => {
        if(error.response.status === 400) {
          this.selectGroupIsBlocked = true
        } else {
          this.$helpers.modalErroServidorPadrao();
        }
      });
    },
    buscaDepartamento(){
      const params = {
        tipo_departamento: 'Regional'
      }
      this.$http.get(this.$api.getDepartmentNational(), {params}).then(({ data }) => {
        this.operatorDepartments = data
      }).catch(() => {
        this.$helpers.modalErroServidorPadrao();
      });
    },
    selecionaTipoEmpresa(tipoEmpresa) {
      this.$emit("getTipoEmpresa", tipoEmpresa);
    },
    selecionaGrupo(grupo) {
      this.value.id_grupo = grupo ? grupo.id_grupo : null
    },
    selecionaDepartamento(departamento){
      this.value.id_operador = departamento ? departamento.id_departamento : null
    },

    verifyPermissions(action) {
      return this.$can(actions[action], subjects.EMPRESAS_AE);
    },
  },

  watch: {
    value: {
      handler(val) {
        if(!val.base_nacional){
          this.value.id_operador = null;
          this.value.id_grupo = null;
        }
        this.$emit('input', this.value);
      },
      deep:true
    },
    'errorApi.base_nacional': {
      handler(val) {
        if(val){
          this.value.base_nacional = true;
          this.value.id_grupo = this.oldGroup.id_grupo;
          this.value.id_operador = this.oldOperator.id_operador;
        }
      }
    }
  },
  computed: {
        grupoSelected: {
          get(){
            let grupoEmpresa = this.gruposEmpresa.filter((grupo) => grupo.id_grupo == this.value.id_grupo)
            if(!grupoEmpresa.length && this.value.id_grupo){
              grupoEmpresa = {
                id_grupo: this.value.id_grupo,
                descricao: this.value.descricao_grupo
              }
              this.oldGroup = grupoEmpresa
            }
            return grupoEmpresa;
          },
          set(value){
            this.grupoSelectedData = value
          }
        },
        departmentSelected: {
            get(){
            let departamento = this.operatorDepartments.filter((departamento) => departamento.id_departamento == this.value.id_operador)
            if(!departamento.length && this.value.id_operador){
              departamento = {
                id_operador: this.value.id_operador,
                descricao: this.value.descricao_operador
              }
              this.oldOperator = departamento 
            }
            return departamento;
          },
          set(value){
            this.departmentSelected = value
          }
        }
    }
};
</script>
<style lang="scss" scoped>
.radio-inline-spacing {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  & > * {
    margin-right: 1.5rem;
    margin-top: 1rem;
    margin-bottom: 0.2rem;
  }
}
.radio-inline-spacing-valor {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    & > * {
      margin-right: 1.5rem;
      margin-bottom: 0.2rem;
    }
  }
</style>
