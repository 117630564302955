<template>
  <b-overlay
    variant="white"
    :show="loading"
    spinner-variant="primary"
    blur="0"
    opacity=".5"
    rounded="sm"
  >
    <ModalAlteracaoDepartamento
      :modalShow="modalAlteracaoDepartamento.showModal"
      @confirmed="confirmarAlteracaoDepartamento"
      @canceled="cancelarAlteracaoDepartamento"
    />

    <b-row v-if="!loading">
      <b-col class="mb-2" cols="12">
        <span class="label_endereco" v-text="label"></span>
      </b-col>
      <b-col xl="4" cols="4">
        <b-form-group
          label="Departamento Regional"
          label-for="departamento-regional-select"
        >
          <b-tooltip
              v-if="unidadeEndereco.bloqueado_departamento || unidadeEndereco.bloqueado"
              :target="'departamento-regional-select-' + index"
              placement="top"
              triggers="hover"
          >
            Não é possível alterar o Departamento Regional da aba atendimento em um endereço de uma empresa cujo Grupo de
            Empresas esteja em uma campanha Ativa ou tenha adesões com doses distribuídas maior que zero
          </b-tooltip>
          <v-select
            :id="'departamento-regional-select-' + index"
            :options="departamentos"
            v-model="departamentoSelecionado"
            placeholder="Selecione o departamento regional"
            label="descricao"
            :clearable="false"
            item-text="descricao"
            item-value="id_departamento"
            :disabled="disabledDepartamento || unidadeEndereco.bloqueado"
          >
            <span slot="no-options">Nenhuma opção selecionável.</span>
          </v-select>
        </b-form-group>
      </b-col>

      <b-col xl="4" cols="4">
        <b-form-group label="Região" label-for="regiao-select">
          <b-tooltip
              :target="'regiao-select-' + index"
              placement="top"
              triggers="hover"
              v-if="unidadeEndereco.bloqueado"
          >
            Não é possível alterar Região ou Unidade Operacional em um endereço de uma empresa que possua vínculo com a
            adesão de uma campanha em andamento e houve doses aderidas
          </b-tooltip>
          <v-select
            :id="'regiao-select-' + index"
            :options="regioes"
            v-model="regiaoSelecionada"
            placeholder="Selecione região"
            label="descricao"
            :clearable="false"
            item-text="descricao"
            item-value="id_regiao"
            :disabled="unidadeEndereco.bloqueado"
          >
            <span slot="no-options">Nenhuma opção selecionável.</span>
          </v-select>
        </b-form-group>
      </b-col>

      <b-col xl="4" cols="4">
        <b-form-group
          label="Unidade Operacional"
          label-for="unidade-operacional-select"
        >
          <b-tooltip
              :target="'unidade-operacional-select-'+ index"
              placement="top"
              triggers="hover"
              v-if="unidadeEndereco.bloqueado"
          >
            Não é possível alterar Região ou Unidade Operacional em um endereço de uma empresa que possua vínculo com a
            adesão de uma campanha em andamento e houve doses aderidas
          </b-tooltip>
          <v-select
            :id="'unidade-operacional-select-' + index"
            :options="unidades"
            v-model="unidadeSelecionada"
            placeholder="Selecione a unidade operacional"
            label="descricao_unidade"
            :clearable="false"
            item-text="descricao_unidade"
            item-value="id_unidade"
            @input="(event) => setUnidadeEndereco"
            :disabled="unidadeEndereco.bloqueado"
          >
            <span slot="no-options">Nenhuma opção selecionável.</span>
          </v-select>
        </b-form-group>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import {BCol, BRow, BOverlay, BFormGroup, BTooltip} from "bootstrap-vue";
import vSelect from "vue-select";
import ModalAlteracaoDepartamento from "@/views/custom-pages/empresa/Modal/ModalAlteracaoDepartamento.vue";
export default {
  components: {
    BTooltip,
    BCol,
    BRow,
    vSelect,
    BFormGroup,
    BOverlay,
    ModalAlteracaoDepartamento
  },

  props: {
    unidadeEndereco: {
      required: true,
    },
    label: {
      required: true,
    },
    regionalDepartmentOptions: {
      required: true,
    },
    usuarioBaseNacional: {
      required: false,
    },
    index: {
      required: true,
    },
    empresaBaseNacional: {
      required: false,
    },
  },

  data() {
    return {
      loading: true,
      departamentos: [],
      departamentoSelecionado: null,
      departamentoSelecionadoInicialmente: null,
      departamentoSelecionadoInicialmenteFiltradoPorUF: null,
      regioes: [],
      regiaoSelecionada: null,
      unidades: [],
      unidadeSelecionada: null,
      modalAlteracaoDepartamento: {
        showModal: false
      },
    };
  },

  mounted() {    
    this.setFormOptions();
  },
  created() {
    this.loading = false;
  },
  computed: {
    disabledDepartamento() {
      return (this.departamentoSelecionado && !this.usuarioBaseNacional && this.regioes.length > 0) || this.unidadeEndereco.bloqueado_departamento || !this.empresaBaseNacional
    },
  },
  methods: {
    buscaRegioesPorDepartamento() {
      return this.departamentoSelecionado == undefined
        ? []
        : this.$http
            .get(this.$api.regiao(), {
              params: {
                ativo: true,
                id_departamento: this.departamentoSelecionado.id_departamento,
                todos: true,
              },
            })
            .then(({ data }) => {
                this.regioes = this.setRegioesPorDepartamento(data);
                return data;
            });

    },

    setRegioesPorDepartamento(regioes) {
      if(regioes.length <= 2) {
        this.regiaoSelecionada = regioes[0]
        return [
            regioes[0]
        ]
      } else {
        return regioes
      }
    },

    buscaUnidadesPorRegiao() {
      return this.regiaoSelecionada == undefined
        ? []
        : this.$http
            .get(this.$api.unidade(), {
              params: {
                ativo: true,
                id_regiao: this.regiaoSelecionada.id_regiao,
                todos: true,
              },
            })
            .then(({ data }) => data);
    },

    async setFormOptions() {
      this.setDepartamentos();

      this.setRegioes(await this.buscaRegioesPorDepartamento());
      if (this.regiaoSelecionada != undefined) {
        this.setUnidades(await this.buscaUnidadesPorRegiao());
      }
    },

    setDepartamentos() {
        this.departamentos = this.regionalDepartmentOptions
        const itemDepartamentoSelecionado = this.departamentos.find(
            (item) => item.id_departamento == this.unidadeEndereco.id_departamento
        )
        this.departamentoSelecionado = itemDepartamentoSelecionado ? itemDepartamentoSelecionado : this.departamentoSelecionado;

        this.departamentoSelecionadoInicialmente = this.departamentoSelecionado

        const departamentoFiltradoPorUF = this.departamentos.find(item => item.uf === this.unidadeEndereco.cidade_uf)
        this.departamentoSelecionadoInicialmenteFiltradoPorUF = departamentoFiltradoPorUF        
    },

    setRegioes(regioes) {
      this.regioes = regioes;
      this.regiaoSelecionada = this.regioes.find(
        (item) => item.id_regiao == this.unidadeEndereco.id_regiao
      );

    },

    setUnidades(unidades) {
      this.unidades = unidades;
      this.unidadeSelecionada = this.unidades.find(
        (item) => item.id_unidade == this.unidadeEndereco.id_unidade
      );

    },

    setUnidadeEndereco() {
      let idDepartamento = null

      if(this.departamentoSelecionado != null) {
        idDepartamento = this.unidadeEndereco.id_departamento ? this.unidadeEndereco.id_departamento : this.departamentoSelecionado.id_departamento
      }
      this.$emit("setUnidadeEndereco", {
        id_empresa_endereco: this.unidadeEndereco.id_empresa_endereco,
        id_departamento: idDepartamento,
        id_regiao: this.regiaoSelecionada ? this.regiaoSelecionada.id_regiao : null,
        id_unidade: this.unidadeSelecionada ? this.unidadeSelecionada.id_unidade : null,
        id_cidade: this.unidadeSelecionada ? this.unidadeSelecionada.id_cidade : null,
        descricao: this.unidadeEndereco.descricao,
        bloqueado: this.unidadeEndereco.bloqueado,
        bloqueado_departamento: this.unidadeEndereco.bloqueado_departamento,
        index: this.index
      });
    },

    closeModalAlteracaoDepartamento() {
      this.modalAlteracaoDepartamento.showModal = false
    },

    confirmarAlteracaoDepartamento() {
      this.closeModalAlteracaoDepartamento()
    },

    cancelarAlteracaoDepartamento() {
      this.closeModalAlteracaoDepartamento()
      this.departamentoSelecionado = this.departamentoSelecionadoInicialmente
    },

  },

  watch: {
    departamentoSelecionado(){
      this.regiaoSelecionada = null
      this.unidadeSelecionada = null
      this.buscaRegioesPorDepartamento();
      if(this.departamentoSelecionado && this.departamentoSelecionadoInicialmente) {
        const departamentoDiferenteDoDepartamentoInicial = this.departamentoSelecionado.id_departamento != this.departamentoSelecionadoInicialmente.id_departamento;
        const departamentoDiferenteDoDepartamentoInicialUF = this.departamentoSelecionado.id_departamento != this.departamentoSelecionadoInicialmenteFiltradoPorUF.id_departamento;
        
        if(departamentoDiferenteDoDepartamentoInicial && departamentoDiferenteDoDepartamentoInicialUF){
          this.modalAlteracaoDepartamento.showModal = true
        }
      }
    },
    regiaoSelecionada: {
      deep: true,
      async handler(_newVal, oldVal) {
        if (oldVal != null || oldVal == undefined) {
          this.setUnidades(await this.buscaUnidadesPorRegiao());
        }
      },
    },
    unidadeSelecionada() {
      this.setUnidadeEndereco();
    },
  },
};
</script>

<style lang="scss" scoped>
.label_endereco {
  font-size: 16px;
  font-weight: 900;
}
</style>