<template>
  <section>
    <b-row>
      <b-col lg="12" md="12" sm="12">
        <b-alert
            variant="warning"
            class="p-1 alert-container"
            :show="true"
        >
          <div class="d-flex align-items-center">
            <feather-icon
                icon="AlertTriangleIcon"
                size="18"
                class="mr-1"
            />
            <span>
            Ao alterar os dados da aba de atendimento da empresa, haverá influência nas <strong>adesões</strong> realizadas
            para essa empresa/endereço, <strong>vacinação</strong> e <strong>geração de relatórios</strong> associados a esse processo
          </span>
          </div>
        </b-alert>
      </b-col>
    </b-row>
    <!-- #enderecos -->
    <div v-if="regionalDepartmentOptions.length > 0">
      <enderecos
        v-for="(unidadeEndereco, index) in value.unidade_endereco"
        :index="index"
        :key="unidadeEndereco.id_empresa_endereco"
        :label="unidadeEndereco.descricao || `Endereço ${index + 1}`"
        :unidadeEndereco="unidadeEndereco"
        :usuarioBaseNacional="usuarioBaseNacional"
        :regionalDepartmentOptions="regionalDepartmentOptions"
        :empresaBaseNacional="empresaBaseNacional"
        @setUnidadeEndereco="setUnidadeEndereco"
      />
    </div>
    <b-col v-else>carregando as informações...</b-col>
  </section>
</template>
<script>
import {
  BCard,
  BFormInput,
  BCol,
  BRow,
  BButton,
  BOverlay,
  BFormGroup,
  BFormRadio,
  BFormRadioGroup, BAlert,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { ValidationProvider } from "vee-validate";
import VueCleave from "vue-cleave-component";
import enderecos from "./partes/enderecos.vue";

export default {
  components: {
    BAlert,
    BCard,
    BButton,
    BFormInput,
    BCol,
    BRow,
    BOverlay,
    BFormGroup,
    BFormRadio,
    BFormRadioGroup,
    vSelect,
    ValidationProvider,
    VueCleave,
    enderecos,
  },

  props: {
    errorApi: {
      type: Object,
      required: true,
    },
    naoIndustria: {
      type: Boolean,
      required: true,
    },
    cidade: {
      type: Object,
      required: false,
    },
    value: {
      type: Object,
      required: true,
    },
    unidade: {
      type: Object,
      required: false,
    },
    usuarioBaseNacional: {
      type: Boolean,
      required: false,
    },
    empresaBaseNacional: {
      type: Boolean,
      required: false,
    }
  },

  computed: {
    valorDiferenciadoComputado() {      
      return this.value.valor_diferenciado;
    },
  },

  data() {
    return {
      casoEdicao: false,
      tiposPreco: [],
      erros: "",
      removeCategoryItem: "Convênio",
      regionalDepartmentOptions: [],
      unidadeEndereco: []
    };
  },

  mounted() {
    this.buscaDepartamentos();
  },

  methods: {
    setUnidadeEndereco(unidadeEndereco) {
      const unidadeEnderecoExistente = this.unidadeEndereco.find(
          item => item.id_departamento === unidadeEndereco.id_departamento
      )

      if(!unidadeEnderecoExistente) {
        this.unidadeEndereco.push(unidadeEndereco)
      }
      this.$emit("setUnidadeEndereco", unidadeEndereco);
    },

    buscaDepartamentos() {
      this.$http
        .get(this.$api.departamento(), { params: { todos: true } })
        .then(({ data }) => {
          this.regionalDepartmentOptions = data;
        });
    },
  },

  watch: {
    value() {
      this.$emit("input", this.value);
    },

    valorDiferenciadoComputado() {
      if (!this.valorDiferenciadoComputado) {
        this.$emit("update:valorDiferenciadoFalso", null);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.radio-inline-spacing-valor {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  & > * {
    margin-right: 1.5rem;
    margin-bottom: 0.2rem;
  }
}
.label_endereco {
  font-size: 16px;
  font-weight: 900;
}

.alert-container strong {
  color: #ff9f43;
}
</style>